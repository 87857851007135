import ApiService from './api.service'

export default class SubareaService {

    getProductsSmall() {
		//return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	async getSubareas(_params) {
        const response = await ApiService.get('api/subareas', _params)
        return response.data
    }

	async newSubarea(subarea) {
        const response = await ApiService.post('api/subareas', subarea)
        return response.data.id
    }

	async updateSubarea(subarea) {
        const response = await ApiService.put('api/subareas/'+subarea.id, subarea)
        return response.data.id
    }

	async deleteSubarea(id) {
        const response = await ApiService.delete('api/subareas/'+id)
        return response
    }

}
