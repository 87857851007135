import ApiService from './api.service'

export default class AreaService {


	async getAreas(_params) {
        const response = await ApiService.get('api/areas', _params)
        return response.data
    }

	async getAreasForSelect(_params) {
        const response = await ApiService.get('api/areas/select', _params)
        return response.data.data
    }
    
	async newArea(area) {
        const response = await ApiService.post('api/areas', area)
        return response.data.id
    }

	async updateArea(area) {
        const response = await ApiService.put('api/areas/'+area.id, area)
        return response.data.id
    }

	async deleteArea(id) {
        const response = await ApiService.delete('api/areas/'+id)
        return response
    }

}
