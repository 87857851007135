<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <!-- Táblázat -->

				<DataTable ref="dt" :value="subareas" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            sortField="subareaname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Települések, településrészek</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="subareaname" header="Megnevezés" sortable></Column>
					<Column field="postcode" header="IRSZ" headerStyle="width: 10rem" sortable></Column>
					<Column field="areaname" header="Körzet" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editSubarea(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteSubarea(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.package }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="subareaDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Település(rész)" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveSubarea()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label">
						<InputText id="subareaname" v-model.trim="v$.subarea.subareaname.$model"
                            required="true" :class="{'p-invalid': v$.subarea.subareaname.$invalid}" autocomplete="new-hosp-subareaname"
                        />
						<label for="fullname">Megnevezés</label>
						<small class="p-error" v-if="v$.subarea.subareaname.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputNumber id="postcode" v-model="v$.subarea.postcode.$model"
                            required="true" :class="{'p-invalid': v$.subarea.postcode.$invalid}" autocomplete="new-hosp-postcode"
                            mode="decimal" :maxFracionDigits="0" 
                            :min="1000" :max="9999"
                        />
						<label for="postcode">IRSZ</label>
						<small class="p-error" v-if="v$.subarea.postcode.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
                        <AutoComplete v-model="v$.subarea.selected_area.$model" :suggestions="filteredAreas" @complete="searchArea($event)" field="name"
                            :minLength="2" :class="{'p-invalid': v$.subarea.selected_area.$invalid}" autocomplete="new-hosp-areaid" placeholder="Keresés 2 karaktertől..."
                            forceSelection required="true"
                        />
						<label for="fullname" style="top: 1px; font-size: 12px;">Körzet</label>
						<small class="p-error" v-if="v$.subarea.selected_area.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="subarea.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveSubarea"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteSubareaDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="subarea">Biztosan töröljük a település(rész)t: <b>{{subarea.subareaname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteSubareaDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteSubarea"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import SubareaService from '@/service/backend/subarea.service'
import AreaService from '@/service/backend/area.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			subareas: null,
			subareaDialog: false,
			deleteSubareaDialog: false,
			deleteSubareasDialog: false,
			subarea: {},
            areas: null,
            filteredAreas: null,
			expandedRows: [],
			filters: {},
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

		}
	},
    validations: {
        subarea: {
            subareaname: { required },
            postcode: { required },
            selected_area: { required }
        }
    },
    subareaService: null,
    areaService: null,
	created() {
		this.subareaService = new SubareaService()
		this.areaService = new AreaService()
    },
    beforeMount() {
        //this.loadCodeStore('subareaClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.lazyParams = {
            first: 0,
            page: 1,
            rows: this.$refs.dt.rows,
            sortField: this.$refs.dt.sortField,
            sortOrder: 1,
            filters: null
        };

        this.loadLazyData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            delete this.lazyParams.filterMatchModes
            this.lazyParams.filters = this.filters.global

            this.subareaService.getSubareas(this.lazyParams)
            .then( result => {
                this.subareas = result.data
                this.totalRecords = result.totalRecords
                this.loading = false
            })
        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.subareas.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        openDialog(){
            this.subareaDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.subarea = {}
            this.openDialog()
		},
		hideDialog() {
			this.subareaDialog = false
            this.submitted = false
		},
        searchArea(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.areaService.getAreasForSelect({filters: event.query })
                .then( result => {
                    this.filteredAreas = result
                })
            }, 600)

        },
		async saveSubarea() {

            this.submitted = true;
            this.v$.subarea.$touch()

            if (!this.v$.$invalid) {

                    try{
                        this.subarea.areaid = this.subarea.selected_area.id
                        this.subarea.areaname = this.subarea.selected_area.name
                        if (this.subarea.id) {
                            this.subarea.id = await this.subareaService.updateSubarea(this.subarea)
                            this.subareas[this.findIndexById(this.subarea.id)] = this.subarea
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.subarea.id = await this.subareaService.newSubarea(this.subarea)
                            if(this.subareas.length == this.$refs.dt.rows){
                                this.subareas.pop()
                            }
                            this.subareas.unshift(this.subarea)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                    }finally{
                        this.submitted = false
                    }

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editSubarea(id) {
            this.subarea = JSON.parse(JSON.stringify(this.subareas.find( d => d.id === id)));
            this.subarea.postcode = parseInt(this.subarea.postcode)
            const val = (this.subarea.areaid) ? {id: this.subarea.areaid, name: this.subarea.areaname} : ""
            this.subarea['selected_area'] = val
            this.filteredAreas = [val]
            this.openDialog()
		},
		confirmDeleteSubarea(subarea) {
			this.subarea = subarea
			this.deleteSubareaDialog = true
		},
		async deleteSubarea() {
            this.submitted = true
            try{
                await this.subareaService.deleteSubarea(this.subarea.id)
                this.subareas = this.subareas.filter(val => val.id !== this.subarea.id)
                this.deleteSubareaDialog = false
                this.subarea = {}
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }
            this.submitted = false
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.subareas.length; i++) {
				if (this.subareas[i].id === id) {
					index = i
					break
				}
			}
			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		}
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'] = val; this.onFilter(); }, 20)
            }, 600)
        }
    },
    computed: {
/*        ...mapGetters({
            codesSubareaClass: 'codeStore/subareaClass'
        })  */
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.subarea-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .subarea-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.subarea-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}


@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
